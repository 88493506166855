/* Reset default browser styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: CentraNo2-Bold,sans-serif;
}

/* Header styles */
.header {
  background-color: #333;
  color: #fff;
  padding: 1px;
  text-align: left;
}

/* Main content styles */
.content {
  max-width: 1200px;
  margin: auto;
  padding: 5px;
}

/* Article card styles */
.article-card {
  flex-grow: 1; /* Equal width for each column */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.article-card h3 {
  font-size: 17px;
  margin-bottom: 5px;
}

.article-card p {
  font-size: 14px;
  font-family: CentraNo2-Book,sans-serif;
  font-weight: normal;
  line-height: 1.5;
}

.article-card a {
  color: #007bff;
  text-decoration: none;
  font-size: 13px;
}

.article-card a:hover {
  text-decoration: underline;
}
