.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2a2c33;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.website-name {
  /*font-size: 33px; !* Adjust font size *!*/
  /*font-weight: bold; !* Make the text bold *!*/
  /* Add any additional styles you want rgba(0,0,0,.8);*/
  background: #282c34;
  color: #fff;
  height: 60px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
}

.website__header {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  height: inherit;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 0;
}

.rectFooter__footer {
  background: #2a2c33;
  padding: 36px 0 25px;
  position: relative;
  z-index: 99;

}

.rectFooter__wrapper {
  display: grid;
  font-family: Lyon,Helvetica,Arial,sans-serif;
  background:url('../public/cover.svg') no-repeat;
  background-size: 75%;
  font-size: 14px;
  color: #fff;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  padding: 10px 10px!important;
  margin: 0 auto;
}


.rectFooter__sectionLink {
  align-items: center;
  border: 2px solid #fff;
  display: inline-flex;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  letter-spacing: 1px;
  padding: 0 28px;
  text-transform: uppercase;
  text-decoration: none;

}

/*.rectFooter-border {*/
/*  border-bottom: 1px solid #ffffff80;*/
/*  grid-column: 1/-1;*/
/*  margin: 0 0 30px;*/
/*  padding: 0 0 14px;*/
/*}*/

.rectFooter-section {
    color: #fff;
    display: grid;
    grid-column: 3/3;
    margin: 0 0 10px;
    row-gap: 20px;
  }

li, ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rectFooter-sectionTitle {
  color: goldenrod;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 20px;
}

.rectFooter-info, .rectFooter-sectionSubText {
  color: #ffffffb3;
  font-size: 10px;
  grid-column: 2;
  letter-spacing: 1px;
  margin: 0 0 15px;
}

.rectFooter-sectionText {
  font-family: Lyon,Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1;
  margin: 0 0 10px;
}

.group {
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 30px;
  max-width: 100%;
  min-width: 100%;
}


.masthead {
  background: rgba(0,0,0,.8);
  color: #fff;
  height: 44px;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;
}

.masthead_menu_container {
  display: flex;
  justify-content: left;
  gap: 15px;
}

.masthead__menu {
  /*background: url() 0 0 no-repeat;*/
  height: 16px;
  width: 20px;
}

button, input, label, select, textarea {
  background: 0 0;
  border: 0;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
}

.masthead__user-login {
  color: #ccc;
}

.masthead__user-label {
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  opacity: 1;
  text-transform: uppercase;
  transition: all .1s ease-out;
  padding-top: 3px;
}

.masthead__title {
  justify-self: center;
  background:url('../public/home_logo.png') 0 0 no-repeat;
  background-size: contain;
  height: 40px;
  text-indent: -200vw;
  width: 200px;

}

.masthead__title__text {
  height: 100%;
  text-align: center;
}

/*a.masthead__btn-subscribe {*/
/*  font-family: CentraNo2,sans-serif;*/
/*  font-weight: 500;*/
/*  font-size: 10px;*/
/*  line-height: 10px;*/
/*  text-align: center;*/
/*  letter-spacing: 1px;*/
/*  text-transform: uppercase;*/
/*  color: #000;*/
/*  background-color: #f7cbf9;*/
/*  padding: 16px 24px 15px;*/
/*  margin-right: 5px;*/
/*}*/

.masthead__interactive {
  display: flex;
  justify-content: right;
}

.masthead__nav {
  background: rgba(0,0,0,.8);
  display: flex;
  height: 0;
  justify-content: center;
  overflow: hidden;
  transition: all .2s ease-out;
}

.masthead__nav-list {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  width: 1180px;
}

.masthead__nav-item {
  font-family: CentraNo2,sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 24px;
  padding: 0 12px;
  text-transform: uppercase;
}


.spacer {
  height: 40px; /* Adjust the height to create space */
}

.top-news-list{
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 5px!important;
}

/* Styles for the wrapper of the results */
.results-wrapper {
  display: flex; /* Use flexbox to display items side by side */
  margin-right: 50px;
  margin-left: 50px;

}

@media screen and (max-width: 600px) {
  .results-wrapper {
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }
}

/* Styles for each column containing a result */
.result-column {
   flex: 1; /* Equal width for each column */
   font-family: CentraNo2-Bold,sans-serif;
   margin-right: 10px; /* Adjust spacing between columns */
   margin-left: 10px;
   }


.result-column-pages  {
  flex: 1 ; /* Equal width for each column */
  font-family: CentraNo2-Bold,sans-serif;
  margin-right: 50px; /* Adjust spacing between columns */
  margin-left: 50px;

}

/*.all-feed {*/
/*  background-color: #ef5b24;*/
/*  border-radius: 2px;*/
/*  color: #fff;*/
/*  font-size: 12px;*/
/*  font-weight: 700;*/
/*  text-transform: uppercase;*/
/*  text-align: center;*/
/*  height: 30px;*/
/*  cursor: pointer;*/
/*  font-family: CentraNo2,sans-serif;*/
/*  padding: 5px 5px!important;*/
/*  margin-left: 20px;*/
/*  margin-bottom: 5px;*/
/*}*/

.all-feed {
  margin: 10px;
  padding: 10px;
  background-color: #ef5b24;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;


}

.grey-button {
  background-color: grey;
}
/* Styles for the container of each result */
/*.result-container {*/
/*  flex-grow: 1;*/
/*  border: 15px solid #ccc; !* Add border for visual separation *!*/

/*}*/

.image-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to 100% of viewport height */
}

.image-container img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
}

.image-container {
  margin-bottom: 20px; /* Add margin to separate the image and video */
}

.image {
  max-width: 100%; /* Ensure image does not exceed container width */
  max-height: 100%; /* Ensure image does not exceed container height */
}

.video-container {
  max-width: 100%; /* Ensure the video doesn't exceed container width */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
